* {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
}
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e5e7eb; /* 2 */
}

.tiptop-editor {
  position: relative;
}
.ProseMirror {
  outline: none !important;
  border: none !important;

  min-height: 100%;

  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 42rem;
  padding: 4rem 2rem;
  caret-color: #000;
  outline-width: 0;
}
.ProseMirror .is-empty::before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
}
ProseMirror.ProseMirror-focused > p.is-empty::before,
ProseMirror.ProseMirror-focused > p.has-focus.is-empty::before {
  content: 'Type  /  to browse options';
}
.ProseMirror.ProseMirror-focused > [data-type='columns'] > [data-type='column'] > p.is-empty.has-focus::before {
  content: 'Type  /  to browse options';
}
.ProseMirror > .is-editor-empty::before {
  content: 'Write a caption …';
}
.ProseMirror blockquote .is-empty:not(.is-editor-empty):first-child:last-child::before {
  content: 'Enter a quote...';
}
.ProseMirror blockquote + figcaption.is-empty:not(.is-editor-empty)::before {
  content: 'Author';
}
.ProseMirror [data-placeholder][data-suggestion]::before,
.ProseMirror [data-placeholder][data-suggestion] *::before {
  content: none !important;
}
.ProseMirror .node-aiWriter.has-focus [data-node-view-wrapper] > *, 
.ProseMirror .node-aiImage.has-focus [data-node-view-wrapper] > *, 
.ProseMirror .node-tableOfContentsNode.has-focus [data-node-view-wrapper] > * {
  border-radius: 0.5rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 2px rgb(0 0 0 / 0.3);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ProseMirror [contenteditable="false"] {
  white-space: normal;
}

.ProseMirror p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1.625;
}
.ProseMirror p:first-child {
  margin-top: 0px;
}
.ProseMirror a {
  color: #3b82f6;
}
.ProseMirror a.link {
  font-weight: 500;
  color: rgb(59 130 246 / 1);
}
.ProseMirror > ul, 
.ProseMirror > ol {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.ProseMirror ul ul, 
.ProseMirror ul ol, 
.ProseMirror ul li, 
.ProseMirror ol ul, 
.ProseMirror ol ol, 
.ProseMirror ol li {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.ProseMirror ul p, 
.ProseMirror ol p {
  margin-top: 0px;
  margin-bottom: 0.25rem;
}
.ProseMirror [contenteditable="false"] {
  white-space: normal;
}

.ProseMirror ul[data-type='taskList'] {
  list-style-type: none;
  padding: 0px;
}
.ProseMirror ul[data-type='taskList'] li {
    display: flex;
}
.ProseMirror ul[data-type='taskList'] li[data-checked='true'] {
  text-decoration-line: line-through;
}
.ProseMirror ul[data-type='taskList'] li > label {
  margin-top: 0.25rem;
  margin-right: 0;
  flex: 1 1 auto;
  flex-shrink: 0;
  flex-grow: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.ProseMirror ul[data-type='taskList'] li > div {
  flex: 1 1 auto;
}
.ProseMirror ul[data-type='taskList'] p {
  margin: 0px;
}

.ProseMirror b, 
.ProseMirror strong {
  font-weight: bolder;
}
.ProseMirror code, 
.ProseMirror kbd,
.ProseMirror samp,
.ProseMirror pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
.ProseMirror h1 {
  font-size: 1.875rem;
  line-height: 2.25rem
}
.ProseMirror h2 {
  font-size: 1.5rem;
  line-height: 2rem
}
.ProseMirror h3 {
  font-size: 1.25rem;
  line-height: 1.75rem
}
.ProseMirror h4 {
  font-size: 1.125rem;
  line-height: 1.75rem
}
.ProseMirror h5 {
  font-size: 1rem;
  line-height: 1.5rem
}
.ProseMirror h6 {
  font-size: 0.875rem;
  line-height: 1.25rem
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6,
.ProseMirror h1 a.link,
.ProseMirror h2 a.link,
.ProseMirror h3 a.link,
.ProseMirror h4 a.link,
.ProseMirror h5 a.link,
.ProseMirror h6 a.link {
  font-weight: 700
}
.ProseMirror h1:first-child,
.ProseMirror h2:first-child,
.ProseMirror h3:first-child,
.ProseMirror h4:first-child,
.ProseMirror h5:first-child,
.ProseMirror h6:first-child {
  margin-top: 0px
}
.ProseMirror h1:last-child,
.ProseMirror h2:last-child,
.ProseMirror h3:last-child,
.ProseMirror h4:last-child,
.ProseMirror h5:last-child,
.ProseMirror h6:last-child {
  margin-bottom: 0px
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3 {
  margin-top: 3rem
}
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  margin-top: 2rem
}

.ProseMirror > blockquote blockquote, 
.ProseMirror [data-type='blockquoteFigure'] blockquote {
  margin: 0px;
}
.ProseMirror figure[data-type='blockquoteFigure'] {
  margin: 3rem 0;
  color: rgb(0 0 0 / 1);
}
.ProseMirror [data-type='blockquoteFigure'] > div {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 4px;
  border-left-color: #000;
  --tw-bg-opacity: 0.8;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 4px;
}
.ProseMirror [data-type='blockquoteFigure'] figcaption {
  margin-top: 1rem;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(115 115 115 / 1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.ProseMirror blockquote .is-empty:not(.is-editor-empty):first-child:last-child::before {
  content: 'Enter a quote...';
}
.ProseMirror blockquote + figcaption.is-empty:not(.is-editor-empty)::before {
  content: 'Author';
}

.ProseMirror code {
  caret-color: white;
  color: white;
  background-color: #171717; /* neutral-900 */
  border-radius: 0.125rem; /* rounded-sm */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06); /* shadow-lg */
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* font-mono */
  line-height: 24px;
}
.ProseMirror code * {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  line-height: 24px;
}
.ProseMirror code::selection {
  background-color: rgba(255, 255, 255, 0.3); /* bg-white/30 */
}
.ProseMirror pre {
  caret-color: white;
  background-color: #3f3f46; /* neutral-700 */
  color: white;
  border-radius: 0.375rem; /* rounded */
  margin: 3rem 0; /* my-12 */
  padding: 1rem; /* p-4 */
  border: 1px solid black; /* border-black */
}
.ProseMirror pre *::selection {
  background-color: rgba(255, 255, 255, 0.2); /* bg-white/20 */
}
.ProseMirror pre code {
  background-color: inherit;
  color: inherit;
  padding: 0;
  box-shadow: none;
}
.ProseMirror .hljs-comment,
.ProseMirror .hljs-quote {
  color: #a3a3a3; /* text-neutral-400 */
}
.ProseMirror .hljs-variable,
.ProseMirror .hljs-template-variable,
.ProseMirror .hljs-attribute,
.ProseMirror .hljs-tag,
.ProseMirror .hljs-name,
.ProseMirror .hljs-regexp,
.ProseMirror .hljs-link,
.ProseMirror .hljs-selector-id,
.ProseMirror .hljs-selector-class {
  color: #fca5a5; /* text-red-300 */
}
.ProseMirror .hljs-number,
.ProseMirror .hljs-meta,
.ProseMirror .hljs-built_in,
.ProseMirror .hljs-builtin-name,
.ProseMirror .hljs-literal,
.ProseMirror .hljs-type,
.ProseMirror .hljs-params {
  color: #fdba74; /* text-orange-300 */
}
.ProseMirror .hljs-string,
.ProseMirror .hljs-symbol,
.ProseMirror .hljs-bullet {
  color: #bef264; /* text-lime-300 */
}
.ProseMirror .hljs-title,
.ProseMirror .hljs-section {
  color: #fde047; /* text-yellow-300 */
}
.ProseMirror .hljs-keyword,
.ProseMirror .hljs-selector-tag {
  color: #5eead4; /* text-teal-300 */
}
.ProseMirror .hljs-emphasis {
  font-style: italic;
}
.ProseMirror .hljs-strong {
  font-weight: 700;
}

.ProseMirror .tableWrapper {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.ProseMirror table {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  border-radius: 0.25rem;
  border-color: rgba(0, 0, 0, 0.1);
}
.ProseMirror table td,
.ProseMirror table th {
  position: relative;
  min-width: 100px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  text-align: left;
  vertical-align: top;
}
.ProseMirror table td:first-of-type:not(a),
.ProseMirror table th:first-of-type:not(a) {
  margin-top: 0px;
}
.ProseMirror table td p,
.ProseMirror table th p {
  margin: 0px;
}
.ProseMirror table td p + p,
.ProseMirror table th p + p {
  margin-top: 0.75rem;
}
.ProseMirror table th {
  font-weight: 700;
}
.ProseMirror table .column-resize-handle {
  pointer-events: none;
  position: absolute;
  bottom: -2px;
  right: -0.25rem;
  top: 0px;
  display: flex;
  width: 0.5rem;
}
.ProseMirror table .column-resize-handle::before {
  margin-left: 0.5rem;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.ProseMirror table .selectedCell {
  border-style: double;
  border-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.05);
}
.ProseMirror table .grip-column,
.ProseMirror table .grip-row {
  position: absolute;
  z-index: 10;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
}
.ProseMirror table .grip-column {
  left: 0px;
  top: -0.75rem;
  margin-left: -1px;
  height: 0.75rem;
  width: calc(100% + 1px);
  border-left-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
}
.ProseMirror table .grip-column:hover::before,
.ProseMirror table .grip-column.selected::before {
  content: '';
  width: 0.625rem;
}
.ProseMirror table .grip-column:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.ProseMirror table .grip-column:hover::before {
  border-bottom-width: 2px;
  border-style: dotted;
  border-color: rgba(0, 0, 0, 0.6);
}
.ProseMirror table .grip-column.first {
  border-top-left-radius: 0.125rem;
  border-color: transparent;
}
.ProseMirror table .grip-column.last {
  border-top-right-radius: 0.125rem;
}
.ProseMirror table .grip-column.selected {
  border-color: rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.ProseMirror table .grip-column.selected::before {
  border-bottom-width: 2px;
  border-style: dotted;
}
.ProseMirror table .grip-row {
  left: -0.75rem;
  top: 0px;
  margin-top: -1px;
  height: calc(100% + 1px);
  width: 0.75rem;
  border-top-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
}
.ProseMirror table .grip-row:hover::before,
.ProseMirror table .grip-row.selected::before {
  height: 0.625rem;
  content: '';
}
.ProseMirror table .grip-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.ProseMirror table .grip-row:hover::before {
  border-left-width: 2px;
  border-style: dotted;
  border-color: rgba(0, 0, 0, 0.6);
}
.ProseMirror table .grip-row.first {
  border-top-left-radius: 0.125rem;
  border-color: transparent;
}
.ProseMirror table .grip-row.last {
  border-bottom-left-radius: 0.125rem;
}
.ProseMirror table .grip-row.selected {
  border-color: rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.ProseMirror table .grip-row.selected::before {
  border-left-width: 2px;
  border-style: dotted;
}

.ProseMirror [data-type='horizontalRule'] {
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 100ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode {
  background-color: rgb(0 0 0 / 0.05);
}
.ProseMirror [data-type='horizontalRule'] hr {
  border-width: 0px;
  border-top-width: 1px;
  border-color: rgb(0 0 0 / 0.2);
  background-color: rgb(0 0 0 / 0.8);
}
.ProseMirror [data-type='horizontalRule'].ProseMirror-selectednode hr {
  border-top-color: rgb(0 0 0 / 0.3);
}

.ProseMirror > *:first-child:first-child,
.ProseMirror [data-type='column'] > *:first-child {
  margin-top: 0px;
}
.ProseMirror > *:first-child:last-child,
.ProseMirror [data-type='column'] > *:last-child {
  margin-bottom: 0px;
}
/*
.ProseMirror [data-type='columns'].has-focus [data-type='column'],
.ProseMirror [data-type='columns']:hover [data-type='column'] {
  border-color: rgb(212 212 212 / 1);
}
.ProseMirror [data-type='columns'] [data-type='column'].has-focus {
  border-color: rgb(163 163 163 / 1);
}
*/
.ProseMirror [data-type='column'] {
  border-radius: 0.25rem;
  border-width: 2px;
  border-style: dotted;
  border-color: transparent;
  padding: 0.25rem;
  transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.ProseMirror [data-type='column']:hover {
  border-color: rgb(245 245 245 / 1);
}
.ProseMirror [data-type='column']:has(.is-active),
.ProseMirror [data-type='column'].has-focus {
    border-color: rgb(245 245 245 / 1);
}
.ProseMirror [data-type='columns'] {
  margin-top: 3.5rem;
  margin-bottom: 3rem;
  display: grid;
  gap: 1rem;
}
.ProseMirror [data-type='columns'].layout-sidebar-left {
  grid-template-columns: 40fr 60fr;
}
.ProseMirror [data-type='columns'].layout-sidebar-right {
  grid-template-columns: 60fr 40fr;
}
.ProseMirror [data-type='columns'].layout-two-column {
  grid-template-columns: 1fr 1fr;
}
.ProseMirror [data-type='column'] {
  overflow: auto;
}
/*
[data-position="left"][data-type="column"],
[data-position="right"][data-type="column"],
[data-position="left"][data-type="column"]:hover,
[data-position="right"][data-type="column"]:hover,
.layout-sidebar-left [data-position="left"][data-type="column"]:hover, 
.layout-sidebar-left [data-position="right"][data-type="column"]:hover, 
.layout-sidebar-right [data-position="left"][data-type="column"]:hover, 
.layout-sidebar-right [data-position="right"][data-type="column"]:hover,
.layout-two-column [data-position="left"][data-type="column"]:hover, 
.layout-two-column [data-position="right"][data-type="column"]:hover {
  border: 1px solid #573cff !important;
  border-radius: 0.5rem !important;
}
*/
[data-type="columns"] [data-type="column"] > * {
  padding: 5px;
}
[data-type="columns"] [data-type="column"] p.is-empty:before {
  content: 'Type  /  to browse options';
}


::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.25rem;
  background-color: rgb(115 115 115 / 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  background-color: rgb(115 115 115 / 0.5);
}


.bubble-menu {
  color: #000; 
  display: inline-flex;
  height: 100%; 
  line-height: 1; 
  gap: 0.125rem; 
  flex-direction: row;
  padding: 0.25rem; 
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb; 
}
.bubble-menu .divider {
  background-color: #e5e7eb;
  height: 100%;
  min-height: 1.5rem;
  width: 1px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.bubble-menu button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  height: 2rem;
  min-width: 2rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  background-color: transparent;
  border: 1px solid transparent;
  color: #6b7280;
  white-space: nowrap;
  cursor: pointer;
}
.bubble-menu .bubble-menu-item {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent; 
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem;
  opacity: 1; 
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.1); 
  color: #1f2937; 
  height: 2rem; 
  gap: 0.25rem; 
  min-width: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: auto; 
}
.bubble-menu button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #374151;
}
.toggle-menu {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: .7rem;
  box-shadow: 3px 2px 5px 2px hsla(0, 0%, 47%, .239);
  display: inline-grid;
  padding: 1rem .5rem;
  position: absolute;
  top: 40px;
}
.bubble-toggle-menu {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
  position: absolute;
  top: 40px;
  min-width: 9rem;
}
.bubble-toggle-menu .font-label {
  font-size: 0.65rem;
  font-weight: 600;
  margin: 0.25rem 0;
  text-transform: uppercase;
  color: #6b7280;
  padding: 0 0.375rem;
}
.bubble-toggle-menu button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border: 0;
  border-radius: 0.375rem;
  cursor: pointer;
}
.bubble-toggle-menu button.is-active,
.bubble-toggle-menu button:hover {
  background-color: #f3f4f6;
  color: #1f2937;
}

.bubble-toggle-menu.link-box {
  position: relative;
  top: 0;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
}
.bubble-toggle-menu.link-box form {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.bubble-toggle-menu.link-box .url-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f3f4f6;
  cursor: text;
}
.bubble-toggle-menu.link-box input {
  flex: 1;
  background-color: transparent;
  outline: none;
  min-width: 12rem;
  color: #000;
  font-size: 0.875rem;
  border: 0;
}
.bubble-toggle-menu.link-box .setlinkBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
  white-space: nowrap;
  height: 34px;
}
.bubble-toggle-menu.link-box .setlinkBtn:disabled {
  opacity: 0.5;
}
.bubble-toggle-menu .toggle-button-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  color: #6b7280;
  margin-top: 10px;
}
.bubble-toggle-menu .toggle-button {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid transparent;
  background-color: #e5e7eb;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 1.25rem;
  width: 2.25rem;
  padding: 0 0.125rem;
}
.bubble-toggle-menu .toggle-button:hover {
  background-color: #d1d5db;
}
.bubble-toggle-menu .toggle-button.checked {
  background-color: #000;
}
.bubble-toggle-menu .toggle-button.checked:hover {
  background-color: #374151;
}
.bubble-toggle-menu .toggle-button span {
  display: block;
  border-radius: 9999px;
  pointer-events: none;
  background-color: #fff;
  height: 1rem;
  width: 1rem;
  transform: translateX(-0.5rem);
  transition: transform 0.2s ease;
}
.bubble-toggle-menu .toggle-button.checked span {
  transform: translateX(0.5rem);
}

.bubble-toggle-menu.button-box {
  padding: 1rem;
  min-width: 18rem;
}
.bubble-toggle-menu.button-box .alignment-buttons {
  padding: 0.25rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 6px;
  background-color:#EAECF0;
  border-radius: 6px;
}
.bubble-toggle-menu.button-box .alignment-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.bubble-toggle-menu.button-box .box-title {
  margin: 0;
}
.bubble-toggle-menu.button-box .alignment-buttons button {
  width: auto;
}
.bubble-toggle-menu.button-box label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}
.bubble-toggle-menu.button-box input {
  flex: 1 1;
  background-color: #f3f4f6;
  outline: none;
  min-width: 12rem;
  color: #000;
  font-size: 0.875rem;
  border: 0;
  height: 40px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-top: 4px;
  width: 100%;
  border: 1px solid #E2E4E9;
  border-radius: 6px;
}
.button-box .submit-button {
  text-align: right;
  margin-top: 12px;
}
.button-box .submit-button button{
  width:auto;
}

.url-block{
  position: relative;
}
.url-block::after{
  content: "";
  background: url('./images/link-icon.svg');
  background-size: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  top: 37px;
}
.url-block .text-box .button-url{
  padding-left: 32px;
}

.link-panel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
}
.link-panel a {
  font-size: 0.875rem;
  text-decoration: underline;
}
.link-panel .divider {
  background-color: #e5e7eb;
  height: 100%;
  min-height: 1.5rem;
  width: 1px;
  margin: 0 0.25rem;
}
.link-panel button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  opacity: 1;
  white-space: nowrap;
  background-color: transparent;
  color: #6b7280;
  height: 2rem;
  gap: 0.25rem;
  min-width: 2rem;
  padding: 0 0.5rem;
  width: auto;
  cursor: pointer;
}
.link-panel button.active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #1f2937;
}
.toolbar-button.active, 
.bubble-toggle-menu button:hover {
  background-color: #ffffff;
  color: #1f2937;
}
.link-panel button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #374151;
}

.toolbar-button > div {
  line-height: 0;
}

.content-type-menu {
  position: absolute;
  left: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.content-type-menu .content-type-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border-radius: 0.375rem;
}
.content-type-menu .content-type-menu-item div {
  line-height: 0;
}
.content-type-menu .content-type-menu-item.active,
.content-type-menu .content-type-menu-item:hover {
  background-color: #f3f4f6;
  color: #111827;
}
.content-type-menu .content-type-menu-title {
  font-size: 0.65rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  color: #6b7280;
  padding: 0 0.375rem;
}
.content-type-menu .content-type-menu-title:first-child {
  margin-top: 0;
}

.ai-menu {
  position: absolute;
  left: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.ai-menu .ai-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border-radius: 0.375rem;
}
.ai-menu .ai-menu-item div {
  line-height: 0;
}
.ai-menu .ai-menu-item:hover {
  background-color: #f3f4f6;
  color: #111827;
}

.colorpicker-wrapper {
  position: absolute;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
  padding: 0.25rem;
  margin-top: 0.5rem;
  min-width: 16.3rem;
}
.colorpicker-cover {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* 2 x 0.25rem (8px) */
}
.colorpicker-cover .react-colorful {
  width: auto;
}
.colorpicker-input {
  width: 100%;
  padding: 0.5rem; /* 2 x 0.25rem (8px) */
  color: #000;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem; /* 4px */
}
.colorpicker-input:focus {
  outline: 1px solid #d1d5db;
}
.colorpicker-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem; /* 1 x 0.25rem (4px) */
}
.colorpicker-buttons .colorpicker-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem; /* 6px */
  border-radius: 0.375rem; /* 6px */
}
.colorpicker-buttons .colorpicker-button:hover,
.colorpicker-buttons .colorpicker-button.active {
  background-color: #f5f5f5; 
}
.colorpicker-buttons .colorpicker-button > div {
  width: 1rem; /* 4px */
  height: 1rem; /* 4px */
  border-radius: 0.25rem; /* 4px */
  background-color: #f1f5f9; /* slate-100 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* shadow-sm */
  outline-offset: 0.125rem; /* 2px */
  outline-color: currentColor; /* ring-current */
}
.colorpicker-buttons .colorpicker-button > div:hover
.colorpicker-buttons .colorpicker-button > div.active {
  outline-width: 1px;
}

.moreoptions-wrapper {
  position: absolute;
  right: -40%;
  bottom: -90%;
  background-color: #ffffff;
  border-radius: 0.5rem; /* 8px */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb; /* neutral-200 */
  padding: 0.25rem; /* 4px */
  margin-top: 0.5rem; /* 8px */
  display: flex;
  flex-direction: row;
}

.tablerow-menu {
  color: #000000; /* text-black */
  display: inline-flex;
  height: 100%;
  line-height: 1;
  gap: 0.125rem; /* 0.5 */
  flex-direction: column;
  padding: 0.5rem; /* 2 */
  background-color: #ffffff; /* bg-white */
  border-radius: 0.5rem; /* rounded-lg */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* shadow-sm */
  border: 1px solid #e5e7eb; /* border-neutral-200 */
}
.tablerow-menu .tablerow-menu-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #71717a;
  text-align: left;
  background-color: transparent;
  width: 100%;
  border-radius: 0.375rem;
}
.tablerow-menu .tablerow-menu-item:hover {
  background-color: #f5f5f5;
  color: #1f2937;
}

.node-tableOfContentsNode.ProseMirror-selectednode .TableOfContentsWrapper {  
  border-radius: 0.5rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 2px rgb(0 0 0 / 0.3);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.TableOfContentsWrapper {
  padding: 0.5rem;
  margin: -0.5rem;
  border-radius: 0.5rem;
}
.TableOfContentsTitle {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #6b7280; /* Neutral 500 */
}
.TableOfContentsCover {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.TableOfContentsCover .TableOfContentsItem {
  display: block;
  font-weight: 500;
  color: #6b7280; /* neutral-500 */
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.875rem; /* text-sm */
  transition: all 0.3s;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}
.TableOfContentsCover .TableOfContentsItem:hover {
  color: #1f2937; /* neutral-800 */
  background-color: rgba(0, 0, 0, 0.05);
}
.TableOfContentsWrapper .TableOfContentsNoData {
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* neutral-500 */
}

.DragHandleItems {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  background-color: transparent;
}
.DragHandleItems.d-none {
  display: none;
}
.DragHandleItems .DragHandleItem {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 0.875rem; /* equivalent to text-sm */
  font-weight: 600; /* equivalent to font-semibold */
  border-radius: 0.375rem; /* equivalent to rounded-md */
  opacity: 1;
  white-space: nowrap;
  background-color: transparent;
  color: #737373; /* equivalent to text-neutral-500 */
  height: 2rem;
  gap: 0.30rem; /* equivalent to gap-1 */
  width: 2rem;
  cursor: pointer;
}
.DragHandleItems .DragHandleItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #3f3f46;
}
.DragHandleItems .DragHandleItem svg,
.DragHandleSubItems .DragHandleSubItem svg,
.menulist-wrapp .menu-label svg {
  width: 1rem;
  height: 1rem;
}
.DragHandleItems .DragHandleItem svg{
  width: 2rem;
}

.DragHandleSubItems {
  position: fixed;
  left: 0px;
  top: 0px;
  transform: translate(36px, 40px);
  min-width: max-content;
  --radix-popper-transform-origin: 0% 0px;
  z-index: auto;
  --radix-popper-available-width: 1346px;
  --radix-popper-available-height: 383px;
  --radix-popper-anchor-width: 34px;
  --radix-popper-anchor-height: 32px;
}
.DragHandleSubItems .DragHandleSubItemsWrapp {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  min-width: 12rem;
  display: flex;
  flex-direction: column;
}
.DragHandleSubItems .DragHandleSubItem {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  text-transform: none;

  display: flex;
  align-items: center;
  gap: 0.5rem; 
  padding: 0.375rem; 
  font-size: 0.875rem;
  font-weight: 500;
  color: #737373; 
  text-align: left; 
  background-color: transparent;
  border-radius: 0.25rem; 
}
.DragHandleSubItems .DragHandleSubItem.dangerItem {
  color: #ef4444;
  background-color: rgba(239, 68, 68, 0.1); 
}
.DragHandleSubItems .DragHandleSubItem:hover {
  background-color: #f5f5f5; 
  color: #1f2937;
}

.DragHandleSubItems .DragHandleSubItem.dangerItem:hover {
  background-color: rgba(239, 68, 68, 0.2);
}
.DragHandleSubItems .sunItemSivider {
  background-color: #e5e5e5; 
  width: 100%; 
  min-width: 1.5rem;
  height: 1px; 
  margin-bottom: 0.4rem;
}

.menulist-container {
  color: #000000;
  max-height: min(80vh, 24rem);
  overflow: auto; 
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e5e5;
}
.menulist-wrapp {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr)); 
  gap: 0.125rem;
}
.menulist-wrapp .menu-title {
  color: #6b7280;
  font-size: 0.65rem;
  grid-column: 1 / -1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  user-select: none; 
  text-transform: uppercase;
  width: 12rem;
}
.menulist-wrapp .menu-label {
  display: flex; 
  align-items: center;
  gap: 0.5rem; 
  padding: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500; 
  color: #0A0A0A; 
  text-align: left;
  background-color: transparent; 
  width: 100%; 
  border-radius: 0.25rem;
  width: 12rem;
  cursor: pointer;
}
.menulist-wrapp .menu-label div {
  line-height: 0;
}
.menulist-wrapp .menu-label.active,
.menulist-wrapp .menu-label:hover {
  background-color: #e5e5e5;
  color: #1f2937; 
}

.ProseMirror [data-type='button'] {
  padding: 12px;
  margin-top: 30px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  cursor: default;
}
.ProseMirror [data-type='button']:hover,
.ProseMirror [data-type='button'].ProseMirror-selectednode {
  border-color: #573CFF;
}
.button-element.align-left {
  text-align: left;
}
.button-element.align-center {
  text-align: center;
}
.button-element.align-right {
  text-align: right;
}
.button-element button {
  background-color: #1c1c1c;
  border: 1px solid #1c1c1c;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 5px 10px;
  transition: all .3s linear;
}
.button-element.width-full button {
  width: 100%;
}
.button-element.width-auto button {
  width: auto;
}

/* book mark section */
.bookmark-node-wrapper,
.vimeo-node-wrapper,
.youtube-node-wrapper,
.other-node-wrapper,
.twitter-node-wrapper,
.spotify-node-wrapper,
.soundcloud-node-wrapper,
.publications-wrapper {
  position: relative;
  margin-top: 1.5rem;
}
.twitter-node-wrapper .none,
.spotify-node-wrapper .none,
.soundcloud-node-wrapper .none,
.other-node-wrapper .none {
	display: none;
}
.bookmark-node-wrapper label,
.twitter-node-wrapper label,
.spotify-node-wrapper label,
.soundcloud-node-wrapper label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0a2540;
  margin-bottom: 5px !important;
}
.bookmark-node-wrapper .urlInputLeft, 
.vimeo-node-wrapper .urlInput,
.youtube-node-wrapper .urlInput,
.other-node-wrapper .urlInput,
.twitter-node-wrapper .urlInputLeft,
.spotify-node-wrapper .urlInputLeft,
.soundcloud-node-wrapper .urlInputLeft {
  padding: 8px 12px;
  gap: 8px;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #573CFF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  font-size: 14px;
}
.other-urlInput .urlInput.embedCode{
  padding: 12px 12px 12px 40px;
  width: 100%;
  max-width: 38rem;
  min-height: 100px;
  resize: vertical;
}
.embed-code iframe {
  max-width: 100%;
}
.bookmark-node-wrapper svg,
.youtube-node-wrapper svg,
.vimeo-node-wrapper svg,
.other-node-wrapper svg,
.twitter-node-wrapper svg,
.spotify-node-wrapper svg,
.soundcloud-node-wrapper svg {
	position: absolute;
	top: 1.5rem;
	left: 14px;
	width: 18px;
}
.twitter-node-wrapper svg,
.spotify-node-wrapper svg ,
.soundcloud-node-wrapper svg,
.youtube-node-wrapper svg,
.vimeo-node-wrapper svg {
  top: 1.4rem !important;
}
.bookmark-node-wrapper .linkbutton-loader , 
.youtube-node-wrapper .linkbutton-loader,
.vimeo-node-wrapper .linkbutton-loader,
.other-node-wrapper .linkbutton-loader,
.twitter-node-wrapper .linkbutton-loader ,
.spotify-node-wrapper .linkbutton-loader,
.soundcloud-node-wrapper .linkbutton-loader {
  min-width: 100%;
  padding: 8px 12px;
  gap: 8px;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
} 
.bookmark-node-wrapper .ring-loader,
.youtube-node-wrapper .ring-loader,
.vimeo-node-wrapper .ring-loader,
.other-node-wrapper .ring-loader,
.twitter-node-wrapper .ring-loader,
.spotify-node-wrapper .ring-loader,
.soundcloud-node-wrapper .ring-loader,
.publications-wrapper .ring-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  margin: 7px auto;
  top: 6px;
  left: 0;
  right: 0;
  animation: rotate 1s linear infinite;
}
.publications-wrapper .ring-loader{
  top: 2px !important;
}
.bookmark-node-wrapper .ring-loader:before,
.youtube-node-wrapper .ring-loader:before,
.vimeo-node-wrapper .ring-loader:before,
.other-node-wrapper .ring-loader:before,
.twitter-node-wrapper .ring-loader:before,
.spotify-node-wrapper .ring-loader:before,
.soundcloud-node-wrapper .ring-loader:before,
.publications-wrapper .ring-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid black;
  animation: prixClipFix 2s linear infinite;
  top: 0;
}
#widget .privacyPolicy{display: none !important;}
.twitter-embed {
  width: 100%;
  max-width: 550px;
  margin: 20px auto;
}
.spotifyEmbed iframe{
  border-radius: 0.5rem !important;
}
.bookmark-node-wrapper .ring-loader.dark:before,
.youtube-node-wrapper .ring-loader.dark:before,
.vimeo-node-wrapper .ring-loader.dark:before,
.other-node-wrapper .ring-loader.dark:before,
.publications-wrapper .ring-loader.dark:before {
  border: 3px solid #868c98;
}
.video-section{
  margin: 20px 0;
}
.linkBox{
  display: flex;

}
.link-content{
  border: 1px solid #EAECF0;
  border-radius: 0.5rem;
  margin: 20px 0;
}
.link-content a {
  text-decoration: none;
}
.link-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem 0 0 0.5rem;
}
.linkImageSection {
  display: flex;
  align-items: center;
  width: 180px !important;
  min-height: 180px;
  height: auto;
}
.linkData {
  width: 70%;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}
.link-content p.linkDataTitle {
  color: #101828;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.link-content .linkDataDiscripation {
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.link-content .siteData {
  display: flex;
  align-items: center;
  width: 100%;
}
.link-content .siteData.siteUrl {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.linkData .siteData:nth-child(3) {
  margin-top: -10px;
}
.link-content .siteData img {
  max-width: min-content;
  margin-right: 6px;
  border-radius: 0;
  object-fit: unset;
  width: auto;
  height: auto;  
  max-width: 20px;
}
.link-content .siteData p {
  margin: 0;
  font-size: 14px;
  color: #475467;
}

.error-message {
  font-size: 14px;
  color: #d92d20;
  display: block;
  margin-top: 0px  !important;
}
.video-wrapper .error-message {
  margin: 5px !important;
}

.video-wrapper .video-caption {
  min-height: 24px;
  text-align: center;
  margin-bottom: 0 !important;
  border: none;
  box-shadow: none;
}
.video-wrapper .video-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the video (optional)';
}
.file-detail .file-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the file (optional)';
  line-height: 26px;
}
.youtube-video, 
.vimeo-video{
  font-size: 14px;
  /* border: 1px solid #573CFF;
  border-radius: 6px; */
  background-color: #fff;
  color: #101828;
  margin-top: 1.5rem;
}
.toolbar-button .menu-icon{
  margin-top: 2px;
}
.toolbar-button .font-size-picker{
  font-weight: 600;
  color: #6b7280;
}
.player-wrapper{
  display: none;
}
.player-wrapper.player-ready {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  line-height: 0;
  /*overflow: hidden;*/
  height: 350px;
  display: block;
}
[data-type='video'] .video-wrapper .player-wrapper.player-ready {
  height: auto;
  min-height: 300px;
}
.video-title{
  outline: none;
  padding: 5px 10px;
  outline: none;
}
.toolbar-button .content-icon{
  margin-top: 3px;
}

.video-wrapper .skeleton-video-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.video-wrapper .skeleton-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ececee;
  border-radius: 6px 6px 0 0;
  animation: loading 1.5s infinite;
}


/* publication */
.publication-list.list .publication-item {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}
.publication-list.list .publication-item:first-child{
  margin-top: 0 !important;
}
.publication-list.list .publication-data {
  padding-left: 15px;
  width: calc(100% - 25%);
  font-size: 14px;
}
.publications-wrapper .publication-title.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.ProseMirror .publications-wrapper a {
  pointer-events: none;
  cursor: pointer;
  color: #000 ;
  text-decoration: none;
  font-weight: 700;
}
.publications-wrapper .publication-description.four-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 3;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}
.publications-wrapper .publication-title{
  margin-bottom: 0;
  line-height: 22px;
  font-size: 16px;
  font-weight: 800;
  color: #101828;
}
.publication-list.list .publication-image{
  width: 25%;
}
.publications-wrapper .publication-image img{
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.alignment-box .menu-title.layout-title{
  margin-bottom: 0;
}
.publication-list.list {
  display: block;
}
.publication-list {
  /*border: 1px solid #573CFF;*/
  border-radius: 6px;
  padding: 16px;
}
.publication-image img {
  width: 100%;
  height: auto;
}
.publication-list.grid {
  display: grid;
  align-items: start;
  column-gap: 30px;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
}
.publication-list.grid .publication-date{
  font-size: 14px;
}
.categories-box .categories-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E2E4E9;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  margin: 5px 0;
  cursor: pointer;
}
.categories-box .select-categories{
  font-size: 14px;
  color: #868C98;
  line-height: 20px;
}
.categories-box .menu-title,
.layout-box .menu-title{
  font-size: 14px;
  line-height: 20px;
  color: #0A0A0A;
}
.NoResults {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  border-bottom-left-radius: 12px;
  min-height: 300px;
  align-items: center;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}
.noResultsBody {
  max-width: 350px;
}
.noResultsBody > svg {
  width: 170px;
  margin-top: 12px;
			
}
.noResTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #101828;
  margin-top: 20px;
  margin-bottom: 8px;
}
.noResDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #475467;
  margin-bottom: 30px;
}

.viemo-urlInput {
  margin-top: calc(1.5rem - 10px);
}

.image-node-wrapper .mediaGallery .ulpoadImageBlock {
  padding-left: 36px;
  padding-right: 36px;
}

.image-node-wrapper .textBlock {
  margin-top: 28px;
  position: relative;
  top: 0;
}
.image-node-wrapper .newpageLeftRow input {
  background: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 0 1px 2px #1018280d;
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  line-height: 24px;
  outline: none;
  padding: 14px 14px 12px;
  width: 100%;
}
.image-node-wrapper  .uploadFileBlock {
  margin: 0 auto;
  max-width: 770px;
  min-height: 281px;
  padding: 85px 10px;
  box-shadow: 0 1px 2px #1018280d;
  cursor: pointer;
  justify-content: center;
  position: relative;
  z-index: 0;
  border: 1px solid #573cff;
  border-radius: 0.5rem;
}
.image-node-wrapper .fileUploadBox .fileUploadCover_Img {
  align-items: center;
  background: #f2f4f7;
  border: 6px solid #f9fafb;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 auto 5px;
  padding: 5px !important;
  width: 40px;
}
.image-node-wrapper .newPageImageUploadText, .uploadFileBlock .newPageImageUploadText {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
  margin-bottom: 0;
    padding-top: 0;
    text-align: center;
}
.image-node-wrapper .newPageImageUploadText span {
  color: #573cff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.image-node-wrapper .image-size-text{
  font-size: 14px;
}
.progress-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}
.progress {
  background: #eaecf0;
  position: relative;
  height: 10px;
  width: 100%;
  border-radius: 15px;
  top: 0;
  left: 0;
}

.progress .color {
  background-color: #573cff;
  width: 0px;
  height: 8px;
  border-radius: 15px;
}
.image-block .video-caption,
.video-wrapper .video-caption {
  min-height: 24px;
  text-align: center;
  margin-bottom: 0 !important;
  outline: none;
}
.image-block .video-caption:before,
.video-wrapper .video-caption::before {
  outline: none;
}
.image-block .video-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the image (optional)';
  font-size: 14px;
  outline: none;
  line-height: 24px;
}
.video-wrapper .video-caption:empty:before {
  pointer-events: none;
  float: left;
  height: 0px;
  width: 100%;
  color: rgb(0 0 0 / 0.4);
  content: 'Write a caption for the video (optional)';
  font-size: 14px;
  outline: none;
  line-height: 26px;
}
.image-block {
  /* border: 1px solid #573cff; */
  border-radius: 0.5rem;
  margin: 20px 0;
}
.image-preview-cover {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.image-preview-cover .image-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.image-preview img{
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  object-fit: cover;
}
.image-bubble-menu{
  padding: 0.5rem !important;
  top: -8px !important;
  min-width: 5rem !important;
  transform: translateX(-50%);
}
.image-bubble-menu button {
  padding: 0 !important;
  display: block;
  width: unset;
  line-height: 0;
}
.image-bubble-menu .image-bubble-block {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.toggle-button-image{
  padding: 9px !important;
}
.image-node-wrapper .errorMessages{
  text-align: center;
  margin-top: 10px;
}
/* .image-block {
  padding: 10px;
} */


.node-fileBlock.ProseMirror-selectednode .fileblock-wrapper {
  border: 1px solid #573cff;
  border-radius: 6px;
}
.file-detail .filedescription .file-caption{
  outline: none;
  width: 100%;
  font-size: 15px;
  color: rgb(55 65 81);
}
.file-caption:empty:before{
  content: 'Enter a description'
}
.file-detail .file-caption.file-title{
  color: #000;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  margin-bottom: 0;
}
.fileblock-wrapper .uploadFileBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}
.file-detail .filedescription,
.uploadFileBlock .file-detail {
  width: 100%;
}
.uploadFileBlock .newPagefileUploadText {
  margin-top: 10px;
  margin-bottom: 0;
}
.fileblock-wrapper .file-block {
  padding: 10px;
  margin: 10px 0;
}
.newPageImageUploadText span {
  color: black;
}
.image-block .uploaded-file {
  margin-top: 10px;
}
.uploadFileBlock .newPageImageUploadText.fileupload {
  display: block;
}
.uploaded-file .newPagefileUploadText {
  font-size: 14px;
}
.uploaded-file .newPagefileUploadText span {
  color: #777;
}
.bubble-toggle-menu.file-bubble-menu{
  min-width: 2.4rem !important;
  top: -22px !important; 
  display: block;
  padding: 2px 0 !important;
}
.file-bubble-menu .file-bubble .full-display-button{
  padding: 5px !important;
  margin-left: 2px;
}

.publication-list.grid .publication-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.publication-list.grid .publication-item .publication-image {
  width: 100%;
  height: 100%;
}
.publication-list.grid .publication-item .publication-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden; 
}
.publication-count .zoom-range,
.publication-column .zoom-range {
  width: 100%;
}
.publication-count input.zoom-range,
.publication-column input.zoom-range {
  height: 4px;
  background-color: #0A2540;
  border-radius: 6px;
  appearance: none;
  padding: 0;
  margin-bottom: 10px;
}
.publication-count input.zoom-range::-webkit-slider-thumb,
.publication-column input.zoom-range::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  background-color: #0A2540;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
  cursor: grab;
}
.publication-count input.zoom-range.slider-cursor::-webkit-slider-thumb,
.publication-column input.zoom-range.slider-cursor::-webkit-slider-thumb {
  cursor: grabbing;
}
.publication-count input.zoom-range::-moz-range-thumb,
.publication-column input.zoom-range::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #344054;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
}
.publication-count input.zoom-range::-ms-thumb,
.publication-column input.zoom-range::-ms-thumb {
  height: 15px;
  width: 15px;
  background-color: #344054;
  border-radius: 50%;
  appearance: none;
  z-index: 99;
}
.publication-count .count-title,
.publication-column .column-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.publication-count .count-title label,
.publication-column .column-title label {
  margin-bottom: 0;
  color: #0A0A0A;
}
.publication-list.grid.one-column {
  grid-template-columns: repeat(1, minmax(0px, 1fr))
}
.publication-list.grid.two-column {
  grid-template-columns: repeat(2, minmax(0px, 1fr))
}
.publication-list.grid.three-column {
  grid-template-columns: repeat(3, minmax(0px, 1fr))
}
.publication-list.grid.four-column {
  grid-template-columns: repeat(4, minmax(0px, 1fr))
}
.bubble-toggle-menu .content-type-menu.categories-menu{ 
  width: calc(100% - 2rem);
  margin-left: 1rem;
  top: 83px;
  height: 25dvh;
  overflow-y: scroll;
  box-shadow: none;
}
.categories-menu .categories {
  font-size: 15px;
  color: #425466;
  padding: 10px;
  cursor: pointer;
  margin: 0;
}
.categories-box .categories {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.categories-box .selected-categorie {
  display: flex;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  align-items: center;
  font-size: 14px;
  padding: 2px;
  padding-right: 0 !important;
  background-color: #EAECF0;
}
.selected-categorie p{
  margin: 0 !important;
}
.categories-box .selected-categorie svg {
  padding-top: 3px;
  /* width: 12px; */
  /* height: 12px; */
  cursor: pointer;
}
.categories-menu .selectedcat:hover {
  background: #f9f9f9;
  color: #101828;
}
.selectedcat {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 20px;
  color: #111827;
  cursor: pointer;
  align-items: center;
}
.content-type-menu.categories-menu .selectedcat input {
  height: 20px;
  min-width: unset;
  width: auto;
  flex: unset;
  margin-left: 4px;
  cursor: pointer;
}
.column-bubble-menu {
  padding-top: 9px !important;
  min-width: 7rem !important;
  top: -40px !important;
}
.AiWriter-node-wrapper {
  border: 1px solid #573cff;
  border-radius: 0.5rem;
}
.AiWriter-node-wrapper .aitext-generator {
  color: rgba(0, 0, 0, 0.8); 
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-left: 0.375rem;
}
.publication-submit-button{
  text-align: right;
}
.product .publication-submit-button {
  margin-top: 10px;
}
.button-box .publication-submit-button button {
  width: auto;
}

.image-preview{
  border: 1px solid #573cff;
  border-radius: 0.5rem;
  margin: 20px 0;
  overflow: hidden;
}
.videoMiddleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-wrapper .react-player {
  margin-top: -5px;
}
.upload-thumbnail {
  position: relative;
  background: rgba(250,250,251,1);
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
  border-radius: 6px;
  cursor: pointer;
}
.button-box .upload-thumbnail .uploadBtn svg{
  position: absolute;
  left: 112px;
  top: 69px;
  padding-top: 3px;
}
.image-bubble-menu-position{
  top: -5px !important;
}
.loop-box p{
  margin: 0 !important;
}
.loop-box .toggle-button span{
  transform: translateX(0) !important;
}
.loop-box .toggle-button.checked span{
  transform: translateX(0.9rem) !important;
}
.gallery-image{
  position: relative;
}
.gallery-image:hover .delete-item-block,.gallery-image:hover .layout-wrapper{
  display: block;
}
.layout-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000033;
}
.delete-item-block {
  position: absolute;
  top: 10px;
  display: none;
  right: 10px;
  z-index: 9;
}
.delete-item-block  .delete-item{
  display: flex;
  flex-direction: row-reverse;
}
.delete-item-block .delete-item .delete-button {
  pointer-events: auto;
  position: relative;
  display: flex;
  height: 1.8rem; 
  width: 1.8rem; 
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem; 
  background-color: rgba(255, 255, 255, 0.9); 
  color: #1F2937; 
  transition: all 0.2s; 
}
.delete-item-block .delete-item .delete-button svg {
  stroke: #6b7280;
}
.delete-item-block .delete-item .delete-button:hover svg {
  stroke: #0A0A0A;
}
.gallery-bubble{
  min-width: 0 !important;
}

.product-node-wrapper .image-node-wrapper .textBlock {
  margin-top: 0;
}
.product-node-wrapper .product-button,
.product-node-wrapper .product-content {
  margin-top: 10px;
} 
.product-content .product-title-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.product-content .product-title,
.product-content .product-description {
  cursor: text;
}
.product-content .product-title-wrapper:has(.rating) .product-title {
  max-width: calc(100% - 110px);
}
.product-content .product-title-wrapper .rating {
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 3px;
}
.product-content .product-title-wrapper .rating .highlighted svg {
  fill: #F2AE40;
}
.product-title {
  margin-bottom: 5px;
  cursor: auto;
  max-width: 100%;
}
.product-title:empty:before{
  content: 'Product title' !important;
  color: #7777;
}
.product-title:focus-visible{
  outline: 0 !important;
  border: 0 !important;
}
.product-description{
  margin: 0 !important;
  cursor: auto;
}
.product-description:empty:before{
  content: 'Description' !important;
  color: #7777;
}
.product-description:focus-visible{
  outline: 0 !important;
  border: 0 !important;
}
.product-node-wrapper{
  border: 1px solid #573cff;
  border-radius: 0.5rem;
  padding: 14px;
}
.product-node-wrapper .image-block{
  padding: 0 !important;
  margin: 0 !important;
}
.product-node-wrapper .image-preview{
  border: 0 !important;
  border-radius: 0.5rem !important;
  margin: 0;
  line-height: 0;
}
.product .toggle-button span{
  transform: translateX(0) !important;
}
.product .toggle-button.checked span {
  transform: translateX(0.9rem) !important;
}
.product .toggle-button-label{
  justify-content: space-between !important;
}
.product {
  min-width: 18rem !important;
  padding: 15px !important;
}
.product .button-element button {
  background-color: #1c1c1c !important;
  border: 1px solid #1c1c1c;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 5px 10px !important;
  transition: all .3s linear;
}
.product-button button {
  width: 100% !important;
}
.rate {
  font-size: 24px;
}
.product-bubble-block .button-wrapp:has(.rating) .rating-label {
  margin-bottom: 3px;
}
.product-bubble-block .button-wrapp .rating .highlighted svg {
  fill: #F2AE40;
}
.product-bubble-block .button-wrapp .rating {    
  text-align: right;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
}


.AiWriter-wrapper {
  padding: 0.5rem; 
  width: 100%; 
  background-color: white; 
  border-radius: 0.5rem; 
  border: 1px solid #E5E7EB;
}
.AiWriter-wrapper .aitext-generator {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
}
.AiWriter-wrapper .aitext-generator label {
  color: rgba(0, 0, 0, 0.8); 
  font-size: 0.75rem; 
  font-weight: 500; 
  margin-bottom: 0.5rem; 
  margin-left: 0.375rem;
}
.AiWriter-wrapper .prompt-textarea {
  background-color: rgba(0, 0, 0, 0.05); 
  border: 0; 
  border-radius: 0.5rem; 
  caret-color: black; 
  display: block; 
  color: black; 
  font-size: 0.875rem; 
  font-weight: 500; 
  height: 4.5rem; 
  padding: 0.25rem 0.5rem; 
  width: 100%; 
  margin-bottom: 0.5rem; 
  transition: background-color 0.2s, outline-color 0.2s;
  max-width: 582px;
  min-height: 100px;
  resize: vertical;
}
.AiWriter-wrapper .prompt-textarea:focus,
.AiWriter-wrapper .prompt-textarea:active {
  background-color: transparent; 
  outline: 1px solid black; 
}
.AiWriter-wrapper .generator-menu {
  display: flex;
  justify-content: space-between; 
  width: auto; 
  gap: 0.25rem;
}
.AiWriter-wrapper .generator-menulist{
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent;
  gap: 0.5rem; 
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem;
  opacity: 1; 
  white-space: nowrap; 
  background-color: #F9FAFB;
  color: #111827; 
  padding: 0.5rem 0.75rem; 
  transition: background-color 0.2s; 
  height: 38px;
  position: relative;
  cursor: pointer;
}
.AiWriter-wrapper .generator-menulist:hover {
    background-color: #E5E7EB;
}
.AiWriter-wrapper .generator-menulist:active {
  background-color: #EDE9FE;
}
.generator-menulist .menuitem{
  padding: 0.5rem; 
  min-width: 12rem; 
  background-color: white; 
  border-radius: 0.5rem; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #E5E7EB;
  position: absolute;
  top: 25px;
  left: -4px;
}
.menuitem .categories {
  display: flex; 
  align-items: center;
  gap: 0.5rem; 
  padding: 0.375rem; 
  font-size: 0.875rem; 
  font-weight: 500; 
  color: #6B7280; 
  text-align: left; 
  background-color: transparent; 
  width: 100%; 
  border-radius: 0.375rem; 
  transition: background-color 0.2s, color 0.2s; 
}
.menuitem .categories:hover {
  background-color: #F3F4F6; 
  color: #1F2937;
}
.AiWriter-wrapper .generate-button {
  display: flex; 
  justify-content: space-between;
  width: auto; 
  gap: 0.25rem;
}
.generate-button .toolbar-button {
  display: flex;
  align-items: center;
  justify-content: center; 
  border: 1px solid black; 
  gap: 0.5rem; 
  font-size: 0.875rem;
  font-weight: 600; 
  border-radius: 0.375rem; 
  opacity: 1; 
  white-space: nowrap; 
  color: white; 
  background-color: black; 
  padding: 0.5rem 0.75rem; 
  transition: background-color 0.2s;
  cursor: pointer;
}
.generate-button .toolbar-button:hover {
  background-color: #1F2937;
}
.generate-button .toolbar-button:active {
  background-color: #1E3A8A; 
}
.generate-button .toolbar-button:disabled {
  opacity: 0.5; 
  pointer-events: none; 
}
.generate-button .toolbar-button.button-discard {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent;
  gap: 0.5rem;
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem; 
  opacity: 1; 
  white-space: nowrap;
  background-color: transparent; 
  color: #F87171; 
  padding: 0.5rem 0.75rem; 
  transition: background-color 0.2s, color 0.2s; 
  cursor: pointer;
}
.generate-button .toolbar-button.button-discard:hover {
  background-color: rgba(248, 113, 113, 0.1);
  color: #F87171;
}
.generate-button .toolbar-button.button-discard:active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #1F2937;
}
.generate-button .toolbar-button.button-discard:disabled {
  opacity: 0.5; 
  pointer-events: none; 
}
.generate-button .toolbar-button.button-insert {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border: 1px solid transparent; 
  gap: 0.5rem; 
  font-size: 0.875rem; 
  font-weight: 600; 
  border-radius: 0.375rem; 
  opacity: 1; 
  white-space: nowrap; 
  background-color: transparent; 
  color: #6B7280; 
  padding: 0.5rem 0.75rem;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
}
.generate-button .toolbar-button.button-insert:hover {
  background-color: rgba(0, 0, 0, 0.05); 
  color: #374151; 
}
.generate-button .toolbar-button.button-insert:active {
  background-color: rgba(0, 0, 0, 0.1); 
  color: #1F2937; 
}
.generate-button .toolbar-button.button-insert:disabled {
  opacity: 0.5;
  pointer-events: none; 
}
.AiWriter-wrapper .preview-title{
  color: rgba(0, 0, 0, 0.8); 
  font-size: 0.75rem; 
  font-weight: 500; 
  margin-bottom: 0.5rem; 
  margin-left: 0.375rem; 
}
.AiWriter-wrapper .aitext-preview{
  background-color: white; 
  border-left: 4px solid #e5e7eb; 
  color: black; 
  font-size: 1rem; 
  max-height: 14rem;
  margin-bottom: 1rem; 
  margin-left: 0.625rem; 
  overflow-y: auto;
  padding-left: 1rem;
  position: relative;
}
.generator-menu .button {
  display: flex;
  align-items: center;
  gap: 8px;
}
.dropdown .downarrow{
  position: absolute;
  top: 12px;
}

.logoslider-wapper .swiper-wrapper {
  align-items: center;
}
.logoslider-wapper .swiper-slide {
  margin: 20px auto;
}
.logoslider-wapper .swiper-slide img {
  max-width: 100%;
}

.categories-button .menu-icon{
  height: 20px !important;
}
.video-wrapper{
  margin: 20px 0;
}
.video-wrapper .image-preview,
.node-galleryBlock .image-block{
  border: 0;
  margin: 0;
  overflow: unset;
}
.node-fileBlock .fileblock-wrapper,
.node-publiations .publications-wrapper,
.node-product .product-node-wrapper,
.node-galleryBlock .image-preview,
.node-VideoBlock .image-preview,
.node-imageBlock .image-preview,
.node-logoSliderBlock .logoslider-wapper {
  border: 1px solid transparent;
}
.node-vimeoBlock .vimeo-video,.gallery-block .image-preview, .image-block .image-preview {
  overflow: hidden;
}
.node-aiWriter.ProseMirror-selectednode .AiWriter-wrapper,
.node-publiations.ProseMirror-selectednode .publications-wrapper,
.node-product.ProseMirror-selectednode .product-node-wrapper,
.node-vimeoBlock.ProseMirror-selectednode .vimeo-video,
.node-galleryBlock.ProseMirror-selectednode .image-preview,
.node-VideoBlock.ProseMirror-selectednode .image-preview,
.node-imageBlock.ProseMirror-selectednode .image-preview,
.node-logoSliderBlock.ProseMirror-selectednode .logoslider-wapper,
.tiptop-editor .ProseMirror.ProseMirror-focused .image-preview{
  border: 1px solid #573cff;
  border-radius: 0.5rem;
  overflow: hidden;
}
.node-aiWriter.ProseMirror-selectednode .AiWriter-wrapper,
.node-logoSliderBlock.ProseMirror-selectednode .logoslider-wapper,
.node-galleryBlock.ProseMirror-selectednode .image-block{
  overflow: unset;
}
.ProseMirror [data-type='columns'] [data-position='left']:hover,
.ProseMirror [data-type='columns'] [data-position='right']:hover,
.ProseMirror [data-type='columns'] [data-position='left'].has-focus,
.ProseMirror [data-type='columns'] [data-position='right'].has-focus {
  border: 1px solid #573cff;
}
.ProseMirror [data-type='columns'] [data-position='left'],
.ProseMirror [data-type='columns'] [data-position='right'] {
  border: 1px solid transparent; 
}
.gallery-block .video-caption:empty:before{
  content:'Write a caption for the Gallery (Optional)'
}
.gallery-block {
  position: relative;
}
.galley-layout-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000033;
}
.image-node-wrapper .dragging{
  opacity: 0.6;
}
.node-VideoBlock.ProseMirror-selectednode .image-preview,
.node-imageBlock.ProseMirror-selectednode .image-preview,
.node-galleryBlock.ProseMirror-selectednode .image-preview{
  border: none;
  border-radius: 0;
}
.node-imageBlock.ProseMirror-selectednode .image-preview{
  line-height: 0;
}

@media (max-width: 767px) {
  .publication-list.grid{
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 20px;
  }
}

@media (max-width: 430px) {
  .publication-list.list .publication-item{
    flex-direction: column;
    margin-top: 20px;
  }
  .publication-list.list .publication-data{
    width: 100%;
    padding-left: 0;
  }
  .publication-list.list .publication-date{
    font-size: 14px;
  }
  .publication-list.list .publication-image,
  .publication-list.grid .publication-image{
    width: 100%;
  }
  .publication-list.list .publication-image img,
  .publication-list.grid .publication-image img{ 
    width: 100%;
  }
  .publication-list.grid{
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
}


@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}
@keyframes prixClipFix {
  0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
      clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
      );
  }

  75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@keyframes loading {
  0% {
      opacity: 0.6;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0.6;
  }
}
